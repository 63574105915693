// components/Accordion.js
import React from 'react'
const Policies = () => {
  const items = [
    {
      title: 'Items of Personal Information to be Collected',
      content:`
        <p class='text-base my-2'>
          LIVECUE collects various types of personal information to provide and enhance our 
          services, ensuring we effectively meet our users' needs. The personal information 
          we collect includes, but is not limited to:
        </p>
        <div class='ml-0 my-1'>
          <b class='text-base'>Right  to  Access : </b>
          <span class='mb-2'> 
            Users have the right to access and use LIVECUE’s services in 
            accordance with these Terms.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Email address : </b>
          <span class='mb-2'> 
            For communication, account verification, and recovery processes. 
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Contact number: </b>
          <span class='mb-2'> 
            For urgent communications and customer support. 
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>User account details : </b>
          <span class='mb-2'> 
            Including username and password for secure access to our services. 
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Payment information : </b>
          <span class='mb-2'> 
            Such as credit card details and billing addresses, to process transactions. 
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Usage  data : </b>
          <span class='mb-2'> 
            This  includes  log  data,  such  as  the  date  and  time  of  access,  IP 
            addresses,  and  activity  within  our  services  to  understand  how  our  services  are 
            used.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Device information : </b>
          <span class='mb-2'> 
            This  includes  log  data,  such  as  the  date  and  time  of  access,  IP 
            addresses,  and  activity  within  our  services  to  understand  how  our  services  are 
            used.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <span class='mb-2'> 
            We also collect non-personal information that cannot be used to identify or contact 
            an  individual,  such  as  anonymous  usage  data,  general  demographic  information, 
            and platform types.
          </span>
        </div>
      `
    },
    {
      title: 'Personal Information Collection Methods',
      content: `
      <p class='text-base my-2'>LIVECUE gathers personal information through various methods to ensure 
      comprehensive data collection and user satisfaction. These methods include:</p>
      <div class='ml-0 my-1'>
        <b class='text-base'>User registration forms : </b>
        <span class='mb-2'> 
          Information provided during the account creation process.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Service usage logs : </b>
        <span class='mb-2'> 
          Automatic data collection when users interact with our services.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Payment processing services : </b>
        <span class='mb-2'> 
          Information collected during transaction processes.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Customer  support  interactions : </b>
        <span class='mb-2'> 
          Details  shared  during  customer  service  inquiries and support requests.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Cookies  and  tracking  technologies : </b>
        <span class='mb-2'> 
          Data  collected  through  cookies  and  similar technologies for tracking usage patterns and preferences.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <span class='mb-2'> 
          We also collect information through surveys, promotions, and other features that 
          require user participation, ensuring we continually improve our service offerings.
        </span>
      </div>
      `,
    },
    {
      title: 'Purposes of Collecting and Using Personal Information ',
      content: `
      <p class='text-base my-2'>
        The  personal  information  collected  by  LIVECUE  is  utilized  for  several  purposes
        aimed at providing an exceptional user experience and maintaining the integrity of 
        our services. These purposes include:
      </p>
      <div class='ml-0 my-1'>
        <b class='text-base'>Service Provision and Maintenance : </b>
        <span class='mb-2 text-base'> 
          To deliver, operate, and maintain our services efficiently.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Transaction Processing : </b>
        <span class='mb-2 text-base'> 
          To manage billing, process payments, and handle related financial activities.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Service  Improvement : </b>
        <span class='mb-2 text-base'> 
          To  analyze  usage  patterns,  improve  existing  features,  and develop new services.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>User Communication : </b>
        <span class='mb-2 text-base'> 
        To send updates, respond to inquiries, provide support, and manage promotional communications.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Security : </b>
        <span class='mb-2 text-base'> 
          To protect against fraud, scams, unauthorized access, and other security issues.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Compliance : </b>
        <span class='mb-2 text-base'> 
          To comply with legal obligations, enforce our terms and conditions, and resolve disputes.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <span class='mb-2 text-base'> 
          We  also  use  personal  information  to  personalize  user  experiences,  ensuring  the content and features are relevant and beneficial to each user
        </span>
      </div>
      `,
    },
    {
      title: 'Sharing and Supply of Personal Information ',
      content: `
      <p class='text-base my-2'>
        LIVECUE values user privacy and does not sell, trade, or otherwise transfer personal 
        information to outside parties except in specific situations:
      
      </p>
      <div class='ml-0 my-1'>
        <b class='text-base'>With User Consent : </b>
        <span class='mb-2 text-base'> 
          Personal information may be shared with third parties when users provide explicit consent.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Legal  Compliance : </b>
        <span class='mb-2 text-base'> 
          We  may  disclose  personal  information  to  comply  with  legal obligations or respond to lawful requests.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Trusted Third Parties : </b>
        <span class='mb-2 text-base'> 
          Personal information may be shared with trusted partners 
          who  help  us  in  operating  our  website,  conducting  our  business,  or  servicing  our 
          users, provided they agree to keep this information confidential.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <span class='mb-2 text-base'> 
          These third parties are carefully selected to ensure they comply with our privacy 
          standards and legal requirements.
        </span>
      </div>
      `,
    },
    {
      title: 'Consignment of Handling Personal Information',
      content: `
      <p class='text-base my-2'>
        LIVECUE may outsource certain functions involving personal information to 
        enhance  service  efficiency.  These  third-party  service  providers  handle  personal 
        information on our behalf under strict confidentiality agreements and are required 
        to adhere to our privacy policies and relevant legal regulations. Examples of such 
        tasks include:
      </p>
      <div class='ml-0 my-1'>
        <b class='text-base'>Payment Processing : </b>
        <span class='mb-2 text-base'> 
          Managed by secure payment gateways.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Data Analysis : </b>
        <span class='mb-2 text-base'> 
          Performed by analytics service providers to understand user behavior and improve services.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Customer Support : </b>
        <span class='mb-2 text-base'> 
          Outsourced support teams to handle user inquiries and provide assistance.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <span class='mb-2 text-base'> 
          These  consignment  arrangements  are  monitored  regularly  to  ensure  compliance 
          and data protection.
        </span>
      </div>
      `,
    },
    {
      title: 'User Rights and How to Exercise Them',
      content: `
      <h4 class='text-base my-2'>Users of LIVECUE have specific rights concerning their personal information. These 
      rights are designed to give users control over their data and include:</h4>
      <div class='ml-0 my-1'>
        <b class='text-base'>Access : </b>
        <span class='mb-2 text-base'> 
          Users can request access to their personal information to review and verify the data we hold.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Corrections : </b>
        <span class='mb-2 text-base'> 
          Users can request corrections to their personal information if they find inaccuracies or incomplete data.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Deletion : </b>
        <span class='mb-2 text-base'> 
          Users have the right to request the deletion of their personal information under certain circumstances.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Objection : </b>
        <span class='mb-2 text-base'>
          Users can object to the processing of their personal information if they believe it is being used inappropriately. 
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Data  Portability : </b>
        <span class='mb-2 text-base'>
          Users  can  request  the  transfer  of  their  personal  information  to another service provider.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <span class='mb-2 text-base'>
          To exercise these rights, users can contact our privacy team at <a class='text-blue-500' href="mailto:info@criclay.com">info@criclay.com</a> . 
          <br/>
          We  strive  to  respond  to  all  requests  promptly  and  in  accordance  with  applicable 
          laws.
        </span>
      </div>
      
      `,
    },
    {
      title: 'Matters Regarding Installation/Operation and Rejection of Automatic Devices Collecting Personal Information ',
      content: `
        <div class='ml-0 my-1'>
          <b class='text-base block text-red-600'>The Purpose of the Use of Cookies </b>
          <span class='mb-2 text-base'>
            LIVECUE uses cookies and similar tracking technologies to enhance user experience and improve our services. The primary purposes of cookies include:
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Personalization : </b>
          <span class='mb-2 text-base'>
            Remembering user preferences and settings to provide a customized experience.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Authentication : </b>
          <span class='mb-2 text-base'>
            Verifying user identity to provide secure access to services.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Analytics : </b>
          <span class='mb-2 text-base'>
            Tracking  usage  patterns  and  interactions  to  understand  user  behavior and improve service features.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Advertising : </b>
          <span class='mb-2 text-base'>
            Delivering relevant advertisements and measuring their effectiveness.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            Cookies help us ensure that users have a seamless and efficient experience while using our services.
          </span>
        </div>
      `,
    },
    {
      title: `<h2 class='text-red-500'>The Installation/Operation and Rejection of Cookies</h2>`,
      content: `Users have the option to manage cookie settings through their browser 
      preferences. Most browsers allow users to accept or reject cookies and to delete 
      existing cookies. However, rejecting or deleting cookies may affect the functionality 
      of some features of our services. Users can typically manage cookie settings in their 
      browser's "Preferences" or "Settings" menu.`,
    },
    {
      title: `<h2 class='text-red-500'>The Permission for Third-Party Accounts</h2>`,
      content: `LIVECUE may integrate with third-party accounts, such as social media platforms or 
      payment gateways. When users link their LIVECUE account to a third-party account, 
      they consent to the sharing of relevant information between LIVECUE and the third 
      party. This information is used to facilitate account integration and authentication 
      and  to  enhance  user  experience.  Users  can  manage  these  integrations  through 
      their account settings.`,
    },
    {
      title: 'Termination and Restriction of Agreement, Compensation for Damages',
      content: `LIVECUE reserves the right to terminate or restrict a user's access to the service at 
      any time if the user violates these Terms or engages in conduct that LIVECUE, in its 
      sole discretion, deems harmful to the platform or its users. Upon termination, users 
      will not be entitled to any refund or compensation for unused services. Users agree 
      to indemnify and hold LIVECUE harmless from any claims, damages, liabilities, and 
      expenses (including legal fees) arising out of or related to their use of the service, 
      violation of these Terms, or infringement of any third-party rights. `,
    },
    {
      title: 'Period of Retention and Use of Personal Information',
      content: `<p class='text-base'>
      LIVECUE retains personal information for as long as necessary to fulfill the purposes 
      outlined  in  this  privacy  policy.  The  retention  period  is  determined  by  various 
      factors, including legal obligations, the necessity of the information for providing 
      services, and user preferences. Specifically:
      </p>
      <div class='ml-0 my-1'>
        <b class='text-base'>Account  Information : </b>
        <span class='mb-2 text-base'>
          Retained  as  long  as  the  user's  account  is  active  and  for  a reasonable period thereafter.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Transaction Data : </b>
        <span class='mb-2 text-base'>
          Retained for audit and compliance purposes as required by law.
        </span>
      </div>
      <div class='ml-0 my-1'>
        <b class='text-base'>Usage Data : </b>
        <span class='mb-2 text-base'>
          Retained for analysis and service improvement purposes, typically in an anonymized form.
        </span>
      </div>
      <p class='text-base'>
        Once  the  retention  period  expires,  personal  information  is  securely  deleted  or 
        anonymized to prevent unauthorized access or use.
      </p>
      `,
    },
    {
      title: 'Destruction Procedures and Methods of Personal Information ',
      content:`
        <div class='ml-0 my-1'>
          <b class='text-base block text-red-600'>Destruction Procedures</b>
          <span class='mb-2 text-base'>
            When personal information is no longer required for the purposes for which it was 
            collected,  LIVECUE  follows  a  systematic  process  to  ensure  its  secure  destruction. 
            The destruction procedures are as follows:
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Identification : </b>
          <span class='mb-2 text-base'>
            Remembering user preferences and settings to provide a customized experience.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Authorization : </b>
          <span class='mb-2 text-base'>
            Obtaining the necessary approvals for the destruction of personal information.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Execution : </b>
          <span class='mb-2 text-base'>
            Carrying out the destruction process in a secure manner, ensuring that the information is irretrievable.
          </span>
        </div>

        <div class='ml-0 my-1'>
          <b class='text-base block text-red-600'>Destruction Method </b>
          <span class='mb-2 text-base'>
            LIVECUE  employs  secure  methods  to  destroy  personal  information,  ensuring  it 
            cannot be reconstructed or accessed:
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Electronic  Records : </b>
          <span class='mb-2 text-base'>
            Permanently  deleted  using  technical  methods  that  prevent 
            recovery, such as data wiping or degaussing.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Physical Records : </b>
          <span class='mb-2 text-base'>
            Shredded, incinerated, or otherwise rendered unreadable.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            These  methods  are  designed  to  protect  user  privacy  and  prevent  unauthorized 
            access to personal information. 
          </span>
        </div>

        <div class='ml-0 my-1'>
          <b class='text-base block text-red-600'>Contact Related to Privacy Operation  </b>
          <span class='mb-2 text-base'>
            For any questions, concerns, or requests regarding our privacy practices, users can 
            contact our dedicated privacy team through the following channel:
          </span>
          <p class='text-base'>
            <b>Email</b>
            <a class='text-blue-500' href="mailto:info@criclay.com">info@criclay.com</a>
          </p>
        </div>
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            Our privacy team is committed to addressing user concerns promptly and 
            effectively, ensuring compliance with our privacy policy and applicable laws.
          </span>
        </div>
        
      `,
      title: 'Additional Policies',
      content:`
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            LIVECUE may implement additional policies to address specific services, changes in 
            legal  requirements,  or  other  relevant  factors.  These  policies  are  designed  to 
            enhance user privacy and data protection. Users will be notified of any additional 
            policies  through  our  website  or  direct  communication.  Examples  of  additional 
            policies include:
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Children's  Privacy : </b>
          <span class='mb-2 text-base'>
            Special  provisions  for  protecting  the  personal  information  of 
            users under the age of 13.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>Data Breach Notification : </b>
          <span class='mb-2 text-base'>
            Procedures for notifying users in case of a data breach 
            affecting their personal information.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <b class='text-base'>International Data Transfers : </b>
          <span class='mb-2 text-base'>
            Policies regarding the transfer of personal information across international borders.
          </span>
        </div>
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            These  additional  policies  ensure  that  LIVECUE  remains  compliant  with  evolving legal standards and best practices in data protection.
          </span>
        </div>
        `,
      title: 'Coverage',
      content:`
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            This privacy policy applies to all LIVECUE users and covers all aspects of our services' 
            personal  information  handling  process.  It  governs  the  collection,  use,  sharing, 
            retention, and destruction of personal information by LIVECUE and its affiliates. The 
            policy  applies  to  all  platforms  and  devices  used  to  access  LIVECUE's  services, 
            including our website, mobile applications, and other related services.
          </span>
        </div>
        `,
        title: 'Revision of the Privacy Policy',
      content:`
        <div class='ml-0 my-1'>
          <span class='mb-2 text-base'>
            LIVECUE  reserves  the  right  to  revise  this  privacy  policy  occasionally  to  reflect 
            changes in our practices, services, legal requirements, or other factors. When we 
            make significant changes to the policy, we will notify users through our website or 
            via  email.  Users  are  encouraged  to  review  the  privacy  policy  periodically  to  stay 
            informed  about  how  we  protect  their  personal  information.  The  revised  privacy 
            policy  will  take  effect  from  the  date  of  notification,  and  continued  use  of  our 
            services constitutes acceptance of the updated terms.
          </span>
        </div>
        `
    },
  ];
  
  return (
    <div className='bg-[#121212] pt-28'>
      <div className="w-full min-h-screen  container mx-auto rounded-2xl">
        <h2 className='text-5xl font-extrabold mb-4 text-center max-w-[550px] mx-auto text-white'>Privacy Policy for <span className='text-rose-700'>LIVECUE</span></h2>
        {items.map((item, index) => (
          <div key={index} className="px-4 pt-4 pb-2 text-base text-gray-300">
            <h2 className='text-2xl font-bold' dangerouslySetInnerHTML={{ __html: item.title }}/>
            <div className='text-base' dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Policies;
