import React from 'react';
import Header from './component/header';
import './App.css';
import Policy from './component/policies';

import TermsAndCondition from './component/term-and-conditions';
import Home from './component/home';
import {Routes, Route } from "react-router-dom";
import Footer from './component/footer';
import { useLocation } from "react-router-dom";
const App = () => {
  const pathname = useLocation();
  React.useEffect(() => {
    window.scrollTo(0,0)
  },[pathname])
  return (
      <div className="min-h-screen bg-gray-100">
        <Header/>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/policies" element={<Policy />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndCondition />} />
          </Routes>
        <Footer/>
      </div>
  );
};

export default App;
