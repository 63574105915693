import React from 'react';
import LiveImg from '../assets/images/logo-white.svg';
import FacebookIcon from '../assets/images/facebook.png';
import YoutubeIcon from '../assets/images/youtube.png';
import { Link } from 'react-router-dom';
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-black text-gray-400 py-6">
      <div className="container mx-auto px-6 text-center">
        <div className="left-footer ">
          <img src={LiveImg} alt='logo icon' className='w-10 mb-10'/>
          <div className='flex sm:flex-row flex-col gap-4 justify-start sm:justify-between items-start sm:items-center border-b border-gray-600 pb-5 mb-5 '>
            <ul className=' text-left justify-start w-full sm:flex-row flex-col  flex text-sm sm:gap-5'>
              <li>
                <Link to='/policies'>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to='/terms-and-conditions'>
                  Terms of Use
                </Link>
              </li>
            </ul>
            <div className='img-block flex gap-4'>
              <img src={FacebookIcon} alt='facebook logo' className='w-10'/>
              <img src={YoutubeIcon} alt='youtube logo' className='w-10'/>
            </div>
          </div>
          <div className='flex text-xs justify-between'>
            <p>	&copy;2023-{currentYear}  LIVECUE. All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;