import React from 'react';
import FeatImg from '../assets/images/feat.jpg';
const LiveStream = () => {
  return (
    <section id="feature" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl sm:text-7xl mb-3 max-w-[600px] mx-auto font-bold text-gray-800">Live Streaming Support Software</h2>
        <p className='text-base md:text-xl max-w-[600px] mx-auto font-light'>Take your live streams to the next level with CriclayLive Studio. Experience high-quality streams with our innovative Scoreboard and Real-time Captioning features.</p>
        <div className='max-w-[500px] mt-3 mx-auto'>
          <img alt='feature logo' src={FeatImg} />
        </div>
      </div>
    </section>
  );
};

export default LiveStream;
