import React from 'react'
import Hero from './hero'
import Ultimate from './ultimate'
import LiveStream from './livestream'
import Professional from './professional'

const Home = () => {
  return (
    <div>
      <Hero/>
      <LiveStream/>
      <Ultimate/>
      <Professional/>
    </div>
  )
}

export default Home;