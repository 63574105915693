import React from "react";
import iosIcon from '../assets/images/app-store-white.svg';
import androidIcon from '../assets/images/play-store-white.svg';
import Lottie from "lottie-react";
import UpArrow from '../assets/images/top.json';
import { Link } from 'react-scroll';
import heroImg from '../assets/images/3.png';
const Hero = () => {
  return (
    <section id='#hero' className="bg-black overflow-hidden hero shadow-lg pb-14 pt-[100px] relative">
      <Link
        to="hero"
        smooth={true}
        duration={500}
        className="w-12 h-12 cursor-pointer rounded-full bg-rose-700 z-20 fixed bottom-[10px] right-[10px]">
        <Lottie animationData={UpArrow} loop={true} />
      </Link>
      <div className="container mx-auto px-6 text-center relative z-10 ">
        <div className='grid lg:grid-cols-2 items-center'>
          <div className="lg:text-left text-center">
            <h1
              className="md:text-7xl text-5xl font-bold text-orange-500 animate__animated animate__fadeInDown">
              Discover Our Live Streaming App
            </h1>
            <p className="text-white mt-4 animate__animated animate__fadeInUp">
              Revolutionizing the way you stream live content.
            </p>
            <div className="mt-6 sm:flex-row flex-col items-center gap-3 lg:justify-start justify-center flex">
              <a
                href="#download"
                className="w-[150px] shadow-lg transition"
              >
                <img alt='apple logo' src={iosIcon}/>
              </a>
              <a
                href="#download"
                className="w-[150px] rounded-md shadow-lg transition "
              >
                <img alt='android logo' src={androidIcon}/>
              </a>
            </div>
          </div>
          <img alt='live cue banner' src={heroImg} />
          {/* <div className=" relative mt-10 flex justify-center items-center">
            <div className="absolute animate__animated top-[100px] w-[250px] z-10 md:w-[250px] animate__fadeInRight border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem]">
                <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                <div className="rounded-[2rem] overflow-hidden bg-[#333] dark:bg-gray-800">
                  <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-light.png" className="dark:hidden " alt=""/>
                  <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-dark.png" className="hidden dark:block " alt=""/>
                </div>
            </div>

            <div className="relative z-1 animate__animated animate__fadeInLeft lg:ml-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-[#333] dark:bg-gray-800">
                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-light.png" className="dark:hidden w-[272px] h-[572px]" alt=""/>
                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-dark.png" className="hidden dark:block w-[272px] h-[572px]" alt=""/>
                </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="marquee">
        <div className="flex 2xl:w-[180%] md:w-[230%] marquee-text items-center gap-10">
          <span className='text-white text-[130px] lg:text-[130px] font-extrabold'>Criclay Live Streaming App</span>
          <span className='text-white text-[130px] lg:text-[130px] font-extrabold mt-[20px]'>Criclay Live Streaming App</span>
        </div>
      </div>
      <div className="scroll-watcher-outer">
        <span>scroll</span>
        <div className="scroll-watcher">
        </div>
      </div>

    </section>
  );
};

export default Hero;
