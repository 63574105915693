import React from 'react';
import MockImg from '../assets/images/social.png';
const Professional = () => {
  return (
    <section id="testimonial" className=" bg-[#171717]">
      <div className="container mx-auto px-6 text-center">
        <div className='grid md:grid-cols-2 items-center'>
          <div className='mock-right text-white text-center md:text-left'>
            <h2 className="text-3xl sm:text-7xl font-bold mb-8">Professional Live Streaming</h2>
            <p className='text-xl'>While live-streaming, you can add various overlays such as images, texts, filters, videos, and audios. The time for preparing for broadcasts will be reduced!</p>
          </div>
          <img alt='mock logo' src={MockImg} />
        </div>
      </div>
    </section>
  );
};

export default Professional;
