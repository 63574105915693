// components/Accordion.js
import React from 'react'
const TermsAndCondition = () => {
  const items = [
    {
      title: 'Purpose',
      content:`
        These Terms & Conditions ("Terms") govern the use of the LIVECUE website and services, 
        including our live streaming platform, Scoreboard, and Real-time Captioning features. 
        LIVECUE provides a comprehensive solution for high-quality live streaming experiences. 
        By accessing or using LIVECUE, users agree to adhere to these Terms, which outline the rights 
        and responsibilities of both LIVECUE and its users. These Terms aim to develop a clear 
        understanding of the terms under which our services are offered and used, ensuring a 
        respectful and secure environment for all participants.
      `
    },
    {
      title: 'Publication and Revision of User Agreement',
      content: `LIVECUE reserves the right to modify these Terms at any time. Revisions posted on 
      our website, will become effective immediately upon publication.  
      Users  are  encouraged  to  review  these  terms  and  stay  informed  of  any  changes. 
      Continued  use  of  LIVECUE  after  any  modifications  signifies  acceptance  of  the 
      updated  Terms.  In  the  event  of  major  changes,  users  will receive  notification  via 
      email  or  through  a  prominent  announcement.  It  is  the  user's  responsibility  to 
      review these notifications and the updated Terms to remain compliant.`,
    },
    {
      title: 'Conclusion of User Agreement',
      content: `A user agreement with LIVECUE is formally concluded when a user registers for an 
      account on our platform. Users confirm that they have read, understood, and agree 
      to abide by these Terms by completing the registration process. The user 
      agreement  constitutes  a  legally  binding  contract  between  LIVECUE  and  the  user, 
      governing the use of our services. Users must provide accurate, complete, and up-
      to-date information during registration and must promptly update their details to 
      reflect  any  changes.  Failure  to  do  so  may  result  in  restrictions  or  termination  of 
      access to our services.`,
    },
    {
      title: 'Notification to Users ',
      content: `LIVECUE communicates important information to users through various channels, 
      including  email,  website  notifications,  and  in-app  messages.  Notifications  may 
      include updates to the Terms, changes to service features, promotional offers, and 
      other relevant communications. It is the user's responsibility to ensure that their 
      contact information is current and that they regularly check for notifications. Users 
      are expected to respond to and promptly respond to and act upon any notices or 
      communications  from  LIVECUE.  LIVECUE  is  not  responsible  for  any  issues  arising 
      from the user’s failure to receive or act upon notifications.`,
    },
    {
      title: 'Change or Termination of Service',
      content: `LIVECUE reserves the right to change, suspend, or terminate any part of our service 
      at  any  time  without  prior  notice.  This  includes  modifying  or  removing  features, 
      altering pricing structures, and discontinuing services. In the case of major changes 
      or termination, users will be notified and provided with information on accessing 
      their data or obtaining a refund for any prepaid services not yet rendered. LIVECUE 
      is not liable for any losses or damages resulting from such changes, suspension, or 
      termination of service. Users are encouraged to regularly review service updates 
      and notifications.`,
    },
    {
      title: 'User Rights and Obligations',
      content: `
      <h4 class='text-xl mb-5'>Users of LIVECUE have certain rights and obligations, including but not limited to:</h4>
      <b class='text-xl'>Right  to  Access: </b>
      <p class='mb-2'> 
        Users have the right to access and use LIVECUE’s services in 
        accordance with these Terms.
      </p>
      <b class='text-xl'> Account Security:</b>
      <p class='mb-2'>
        Users are responsible for safekeeping their confidential account 
        information,  including  passwords.  They  must  notify  LIVECUE  immediately  of  any 
        unauthorized use or security breaches.
      </p>
      <b class='text-xl'>Prohibited Conduct:</b> 
      <p class='mb-2'>
        Users must not engage in illegal activities, such as 
        unauthorized access, data mining, or distributing malware. Additionally, they must 
        avoid actions that could damage, disable, or impair the service, or interfere with 
        other users' experience.
      </p>
      <b class='text-xl'>Content Responsibility:</b> 
      <p class='mb-2'>
        Users are responsible for the content they upload, stream, 
        or  share  on  LIVECUE.  They  must  ensure  that  their  content  does  not  infringe  on 
        intellectual property rights or violate any laws or regulations.
      </p>
      <b class='text-xl'>Compliance with Laws:</b> 
      <p class='mb-2'>
        Users must comply with all applicable local, state, national, 
        and international laws and regulations while using LIVECUE. This includes adhering 
        to data protection and privacy laws.
      </p>
      `,
    },
    {
      title: 'Publication of Ads',
      content: `LIVECUE  may  display  advertisements  on  its  platform.  By  using  LIVECUE,  users 
      consent  to  the  display  of  such  ads.  Advertisers  are  solely  responsible  for  the 
      content and legality of their advertisements. LIVECUE does not endorse or 
      guarantee the products, services, or information advertised and is not responsible 
      for  any  transactions  or  interactions  between  users  and  advertisers.  Users  should 
      exercise  caution  and  perform  their  own  due  diligence  before  engaging  with 
      advertisements or purchasing advertised products and services.`,
    },
    {
      title: 'Intellectual Property Rights and Warranties',
      content: `All intellectual property rights in the LIVECUE platform, including software, content, 
      and technology, are owned by LIVECUE or its licensors. Users are granted a limited, 
      non-exclusive,  non-transferable  license to access and use LIVECUE’s services in 
      accordance  with  these  Terms.  This  license  does  not  permit  users  to  modify, 
      reproduce, distribute, or create derivative works based on LIVECUE’s intellectual 
      property without express written permission. 
      LIVECUE provides the service "as is" without any guarantees regarding its accuracy, 
      reliability, or availability, and does not ensure it will be uninterrupted, error-free, 
      or secure. `,
    },
    {
      title: 'The Company’s Limitation on Liability',
      content: `To  the  fullest  extent  permitted  by  law,  LIVECUE  is  not  liable  for  any  indirect, 
      incidental,  special,  consequential,  or  punitive  damages  related  to  the  use  or 
      inability to use the service, including but not limited to loss of profits, data, or other 
      intangible losses. 
      LIVECUE’s liability for direct damages is limited to the amount paid by the user for 
      the service during the period in which the damages occurred. In jurisdictions where 
      excluding or limiting liability for consequential or incidental damages is prohibited, 
      LIVECUE’s liability is limited to the maximum extent permitted by law.`,
    },
    {
      title: 'Termination and Restriction of Agreement, Compensation for Damages',
      content: `LIVECUE reserves the right to terminate or restrict a user's access to the service at 
      any time if the user violates these Terms or engages in conduct that LIVECUE, in its 
      sole discretion, deems harmful to the platform or its users. Upon termination, users 
      will not be entitled to any refund or compensation for unused services. Users agree 
      to indemnify and hold LIVECUE harmless from any claims, damages, liabilities, and 
      expenses (including legal fees) arising out of or related to their use of the service, 
      violation of these Terms, or infringement of any third-party rights. `,
    },
    {
      title: 'Restriction on Use',
      content: `<h4 class='text-xl mb-5'>
        LIVECUE  imposes  restrictions  on  the  use  of  its  service  to  maintain  a  safe  and 
        respectful environment for all users. The following activities are prohibited: 
      </h4>
      <b class='text-xl'>Unauthorized Access:</b>
      <p class='mb-2'> 
        Attempting  to  gain unauthorized  access to  any  part  of  the 
        service, including other users’ accounts, or LIVECUE’s systems. 
      </p>
      <b class='text-xl'>Content Violations:</b>
      <p class='mb-2'> 
        Uploading, streaming, or sharing content that is illegal, 
        harmful, abusive, defamatory, obscene, or otherwise objectionable.
      </p>
      <b class='text-xl'>Intellectual  Property  Infringement:</b>
      <p class='mb-2'> 
        Violating  the  intellectual  property  rights  of 
        LIVECUE or third parties.
      </p>
      <b class='text-xl'>Malicious  Activities: </b>
      <p class='mb-2'> 
        Engaging  in  activities  that  disrupt,  damage,  or  impair  the 
        service, or that interfere with other users’ enjoyment of the service.
      </p>
        Violations of these restrictions could lead to the suspension or termination of the 
        user’s account and might also lead to legal action where appropriate.
      `,
    },
    {
      title: 'Jurisdiction and Governing Law',
      content: `<p className='mb-2'>These  Terms  are  governed  by  and  construed  in  accordance  with  the  laws  of 
      Pakistan. Any disputes arising out of or related to these Terms or the use of LIVECUE 
      shall  be  subject  to  the  exclusive  jurisdiction  of  the  courts  located  in  Pakistan.  By 
      using  LIVECUE,  users  consent  to  the  jurisdiction  and  venue  of  these  courts  and 
      agree to resolve any disputes in accordance with the applicable laws.</p>
      <p className='mb-2'>By  accessing  and  using  LIVECUE,  users  agree  to  comply  with  these  Terms  & 
      Conditions. If users do not agree to these Terms, they should discontinue their use 
      of  LIVECUE.  We  are  committed  to  providing  a  secure  and  enjoyable  service  and 
      appreciate users’ cooperation in maintaining a positive environment. </p>
      `,
    },
  ];
  
  return (
    <div className='bg-[#121212] pt-28'>
      <div className="w-full min-h-screen  container mx-auto rounded-2xl">
        <h2 className='text-5xl font-extrabold mb-4 text-center max-w-[550px] mx-auto text-white'>Terms & Conditions for <span className='text-rose-700'>LIVECUE</span></h2>
        {items.map((item, index) => (
          <div key={index} className="px-4 pt-4 pb-2 text-base text-gray-300">
            <h2 className='text-2xl font-bold'>{item.title}</h2>
            <div className='text-base' dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndCondition;
