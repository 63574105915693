import React from 'react';
import dummyImage from '../assets/images/2.png';
const Ultimate = () => {
  return (
    <section id="screenshot" className=" py-12 bg-black">
      <div className="container mx-auto px-6 text-center">
        <div className='grid md:grid-cols-2 items-center'>
          <div className='screenshots-left'>
            <div className='w-[50%]  sm:w-[300px] mx-auto md:ml-auto md:mr-[100px]'>
              <img alt='dummy logo' src={dummyImage} className='sm:w-full'/>
            </div>
          </div>
          <div className='md:mt-0 mt-10 screenshots-right mx-auto max-w-[500px] text-white text-center sm:text-left'>
            <span className='text-orange-500 text-center inline-block md:text-left'>LiveCue</span>
            <h2 className="text-3xl sm:text-7xl text-center md:text-left font-bold mb-8">Your Ultimate Live Streaming Powerhouse!</h2>
            <p className='text-xl'>Effortlessly broadcast live on YouTube, Facebook, and Twitch with our sleek, pro-grade app. Stream your unforgettable moments anytime, anywhere!</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ultimate;