// components/Header.js
import { useState } from "react";
import { Transition } from "@headlessui/react";
import Logo from "../assets/images/logo-white.svg";
import { Link } from "react-scroll";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-black border-b-2 border-orange-600 fixed w-full top-0 z-20 text-white shadow-md">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="text-lg font-bold">
          <a href="/">
            <img src={Logo} alt='logo icon' className="w-12" />
          </a>
        </div>
        <nav className="hidden md:flex space-x-6">
          <a
            href="/"
            className="hover:text-gray-400 cursor-pointer border-b-red-500 focus-visible:border-b-2 "
          >
            Home
          </a>
          <Link
            to="feature"
            smooth={true}
            duration={500}
            className="hover:text-gray-400 cursor-pointer"
            offset={-80}
          >
            Feature
          </Link>
          <Link
            to="screenshot"
            smooth={true}
            duration={500}
            className="hover:text-gray-400 cursor-pointer"
            offset={-80}
          >
            Screenshot
          </Link>
          <Link
            to="testimonial"
            smooth={true}
            duration={500}
            className="hover:text-gray-400 cursor-pointer"
            offset={-80}
          >
            Testimonial
          </Link>
        </nav>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div ref={ref} className="md:hidden">
            <div className="px-4 flex flex-col pt-2 pb-3 space-y-5 sm:px-3">
              <a
                href="/"
                className="hover:text-gray-400 cursor-pointer border-b-red-500 focus-visible:border-b-2 "
              >
                Home
              </a>
              <Link
                to="feature"
                smooth={true}
                duration={500}
                className="hover:text-gray-400 cursor-pointer"
                offset={-80}
              >
                Feature
              </Link>
              <Link
                to="screenshot"
                smooth={true}
                duration={500}
                className="hover:text-gray-400 cursor-pointer"
                offset={-80}
              >
                Screenshot
              </Link>
              <Link
                to="testimonial"
                smooth={true}
                duration={500}
                className="hover:text-gray-400 cursor-pointer"
                offset={-80}
              >
                Testimonial
              </Link>
            </div>
          </div>
        )}
      </Transition>
    </header>
  );
};

export default Header;
